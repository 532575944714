/* Path: src\index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/Aeonik/Aeonik-Thin.otf');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/Aeonik/Aeonik-ThinItalic.otf');
  font-style: italic;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/Aeonik/Aeonik-Light.otf');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/Aeonik/Aeonik-LightItalic.otf');
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/Aeonik/Aeonik-Regular.otf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/Aeonik/Aeonik-RegularItalic.otf');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/Aeonik/Aeonik-Medium.otf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/Aeonik/Aeonik-MediumItalic.otf');
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/Aeonik/Aeonik-Bold.otf');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/Aeonik/Aeonik-BoldItalic.otf');
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/Aeonik/Aeonik-Black.otf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url('./assets/Aeonik/Aeonik-BlackItalic.otf');
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}
::-webkit-scrollbar {
  width: 20px;
  height: 20px;
  border-top: 0px solid #eee;
  background-color: transparent;
  border-left: 0px solid #eee;
}

::-webkit-scrollbar-thumb {
  height: 3px;
  border: 7px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: #ccc;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
body {
  font-family: 'Aeonik', sans-serif;
}

input {
  font-family: 'Aeonik', sans-serif !important;
}

.full-shadow-lg {
  -moz-box-shadow: 0 0 8px #ccc;
    -webkit-box-shadow: 0 0 8px #ccc;
    box-shadow: 0 0 8px #ccc;
}