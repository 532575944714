.react-datepicker {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  border: 0px !important;
}

.react-datepicker-popper {
  width: 300px;
  transform: translate(-40px, 40px) !important;
}

.react-datepicker__triangle {
  transform: translate(110px, 0px) !important;
}

.react-datepicker__navigation {
  top: 8px !important;
  color: rgb(51, 51, 51) !important;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: 1px solid #aeaeae26 !important;
}

.react-datepicker__month-text--keyboard-selected {
  background-color: rgb(175, 22, 133);
  color: white;
}

.react-datepicker__month-text:hover {
  background-color: rgba(175, 22, 134, 0.377);
}

.react-datepicker__triangle::after {
  border-bottom-color: #ffffff !important;
}
