/* Clases de las celdas */

.ag-theme-alpine,
.ag-theme-fedefarma {
  font-family: 'Aeonik', sans-serif !important;
}

.ag-theme-alpine .ag-cell {
  color: #737373 !important; /* Color del texto de las celdas */
  display: flex;
  align-items: center;
  font-size: 13px;
}

.ag-text-field-input {
  font-weight: normal;
}

.ag-theme-alpine .ag-ltr input[class^='ag-'][type='date'] {
  padding-right: 6px !important;
}

/* Clases de los encabezados */
.ag-theme-fedefarma .ag-header-row-column-filter {
  background-color: #fff !important;
}

.ag-theme-alpine .ag-header-cell {
  background-color: #fff !important;
  border: none !important;
  font-weight: 600 !important;
}

.ag-header-cell .ag-floating-filter .ag-focus-managed {
  border: none !important;
}

.ag-theme-alpine {
  --ag-header-row-column-filter: #c4c4c4;
}

/* Clases de las filas */
.ag-theme-alpine .ag-row {
  height: 50px !important;
  max-height: 80px !important;
  transform: none !important;
  position: relative;
  display: block;
}

.ag-row {
  cursor: pointer;
  border-width: 0 !important;
}

.ag-row-even {
  background-color: #fafafa !important; /* Cambiar a gris claro */
}

.ag-row-odd {
  background-color: #fff !important; /* Cambiar a blanco */
}

.ag-row:hover {
  background-color: #cde8e8 !important;
}

.ag-row-selected {
  background-color: #cde8e8 !important;
}

/* Clases de los inputs */
.ag-theme-fedefarma input {
  height: 32px !important;
}

/* Clases de los contenedores */
.ag-react-container {
  overflow-x: auto !important;
  overflow-y: hidden !important;
  width: 100%;
  position: relative;
}

.ag-react-container > :not(:first-child) {
  display: none !important;
}

/* ag-react-container direct span child */
.ag-react-container > span {
  display: flex !important;
}

.ag-center-cols-clipper .ag-center-cols-container {
  overflow: hidden !important;
}

/* Pagination component */
.ag-paging-panel .ag-paging-page-size {
  display: none !important;
}

.ag-paging-panel .ag-paging-page-summary-panel {
  display: none !important;
}
.ag-paging-panel .ag-paging-row-summary-panel {
  display: none !important;
}
